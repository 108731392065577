import * as Sentry from '@sentry/nextjs';
import { version } from '~/package.json';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.SENTRY_DSN) {
  Sentry.init({
    // Uncomment when ready for production
    enabled: publicRuntimeConfig.NODE_ENV === 'production' || publicRuntimeConfig.NODE_ENV === 'staging',
    dsn: publicRuntimeConfig.SENTRY_DSN,
    environment: publicRuntimeConfig.NODE_ENV,
    tracesSampleRate: 1.0,
    release: version,
  });

  // Add more tags if necessary
  // Sentry.configureScope((scope) => {
  // });
}
