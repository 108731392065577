import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;

   }

 :focus {
    outline: none !important;
}



  html, body {
    width: 100%;
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
    font-size: 100%;
    line-height: 1.4;
    /* background-color: #f5f7f9; */
    color: #4b5765;
    font-family: "AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 100%;
    font-weight: 400;
  }

  body {
    font-family: ${({ theme }) => theme.primaryFont};
    color: black;
    line-height: 1.4;
    font-size: 100%;
    height: 100%;
  }

  button {
    transition: 200ms;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    transition: 200ms;
    text-decoration: none;


    &:hover {
      color: ${({ theme }) => theme.linkHoverColor};
      text-decoration: none;
    }
  }

  ul, ol, dl {
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

input:invalid {
    box-shadow: none;
}

button {
    background: transparent;
    border: 0;
    padding: 0;
    outline: none;
    cursor:pointer;
}

h4 {
    font-size: 1.25em;
    line-height: 1.4;
    margin-bottom: 0.4em;
}


  /* Fix for autocomplete blue background at input - Google chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.pac-container{
  background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
}
.pac-item{
    cursor: pointer;
    font-size: 13px;
    padding: 8px 12px;
}

.pac-item:hover{
  background-color: #DEEBFF;

}

#js-nav-searchbar1 > div{
  background-color:transparent;
  border-radius:8px;    
  margin-top: 8px;
}

span.pac-matched::after {
    content: ', ';
}

span.pac-matched {
  font-size: 16px;
  font-weight: 600;
}

div[class*='SearchInputContainer']{
  margin-top:0px !important;
}



:root {

/* Colors: */
--water: #0751C2;
--deep-sea: #001D58;
--seaweed: #0E7873;
--moss: #2A8500;
--seafoam: #72D4BA;
--dusk: #84365D;
--sunset: #BC350A;
--sunrise: #F1A290;
--mid-rock: #8E9697;
--light-stone: #F9FAFE;
--stone: #DBDFE5;
--rock: #5E696A;
--midnight: #17233C;
--sand: #F5ECE4;
--sun: #F4C064;

/* Font/text values */
--unnamed-font-family-avenir-next-w1g: Avenir Next W1G;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-bold: bold;
--unnamed-font-size-32: 32px;
--unnamed-character-spacing-0: 0px;
--unnamed-line-spacing-65: 65px;
}

/* Character Styles */
.h2 {
font-family: var(--unnamed-font-family-avenir-next-w1g);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-32);
line-height: var(--unnamed-line-spacing-65);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-17233c);
}




`;

export default GlobalStyle;
