import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as JotaiProvider } from 'jotai';
import AppProvider from '~/providers/AppProvider';
import getConfig from 'next/config';
import { saveUTMParams } from '~/utils/UTMParams';
import GlobalStyle from '~/styles/globalStyles';
import '@boatsetter-backup/js-component-lib/lib/prod.css';
import 'node_modules/react-modal-video/css/modal-video.min.css';
import 'react-modal-overlay/dist/index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'react-intl-tel-input/dist/main.css';

// Global theme variables
const theme = {
  primaryFont: '"AvenirNext", "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
  linkColor: 'black',
  linkHoverColor: 'white',
};

export default function MyApp({ Component, pageProps, langProps }: AppProps & { langProps: any }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    saveUTMParams();

    const { publicRuntimeConfig } = getConfig();
    if (publicRuntimeConfig.NODE_ENV === 'production' && !window.console) {
      // @ts-expect-error
      window.console = {};
      const methods = ['log', 'debug', 'warn', 'info'];
      for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {};
      }
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <JotaiProvider>
            <AppProvider {...langProps}>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Component {...pageProps} />
              </ThemeProvider>
            </AppProvider>
          </JotaiProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx, Component }) => {
  // manually call getInitialProps on child component (just in case the page component is using it)
  const userAgent = ctx && ctx.req ? ctx.req.headers['user-agent'] : '';
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

  const langProps = await AppProvider.getInitialProps(ctx);

  return { pageProps, langProps, userAgent };
};
